<template>
  <div v-if="$route.path !== '/livevideo'">
    <div class="md-nav-welcome" v-if="isLogin">
      <p>
        欢迎
        {{
          userInfo
            ? userInfo?.dept?.deptName || userInfo.deptName
            : deptNameinfo
        }}
      </p>
      <!-- <p>123123</p> -->
    </div>
    <header
      v-if="!$store.state.specialRouters.includes($route.path)"
      class="md-shared-header"
      :class="[$store.state.fixed ? 'md-shared-header-fixed' : '']"
      @click="isShowSearchBox = false"
      :style="{ top: isLogin ? '30px' : '0px' }"
    >
      <div class="md-nav-box">
        <div class="md-nav-panel">
          <div style="margin-right: 30px; display: flex; align-items: center">
            <img
              v-if="userInfo && userInfo.dept.deptId === 269"
              src="@/assets/img/icon.png"
              @click="handleNav({ path: '/home' })"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/icon.png"
              @click="handleNav({ path: '/home' })"
              alt=""
            />
            <span
              class="span-title"
              v-if="
                userInfo &&
                ((userInfo.dept && userInfo.dept.deptName === '吉林艺术学院') ||
                  (userInfo.deptName && userInfo.deptName === '吉林艺术学院'))
              "
              >多媒体学习及学科服务平台
            </span>
            <span
              class="span-title"
              v-if="
                userInfo &&
                ((userInfo.dept && userInfo.dept.deptName === '吉林工商学院') ||
                  (userInfo.deptName && userInfo.deptName === '吉林工商学院'))
              "
              >多媒体数据库
            </span>
            <span v-else> 外语学习平台</span>
          </div>

          <ul class="class-list">
            <li
              :class="['class-item', { download: item.name == '下载和关注' }]"
              v-for="(item, index) in navList"
              :key="index"
              @click="handleNav(item)"
            >
              <span
                :class="$route.path === item.path ? 'active' : 'item-span'"
                >{{ item.name }}</span
              >

              <div v-if="item.child" class="child">
                <ul>
                  <li
                    v-for="(child, i) in item.child"
                    :key="i"
                    class="child-li"
                    @click="handleNav(child)"
                  >
                    <el-tooltip
                      class="item"
                      effect="light"
                      placement="left"
                      popper-class="tooltip-header"
                    >
                      <img
                        slot="content"
                        style="width: 90px; height: 90px"
                        :src="child.img"
                        alt=""
                      />
                      <div>
                        <img
                          :src="child.icon"
                          alt=""
                          style="width: 20px; height: 20px; margin-right: 5px"
                        />
                        <span>{{ child.name }}</span>
                      </div>
                    </el-tooltip>
                  </li>
                </ul>
              </div>
            </li>

            <li
              class="class-item"
              v-if="
                userInfo &&
                ((userInfo.dept && userInfo.dept.deptName === '吉林艺术学院') ||
                  (userInfo.deptName && userInfo.deptName === '吉林艺术学院'))
              "
              @click="goPage"
            >
              <span class="item-span">学科资源</span>
            </li>

            <!-- 只有首页展示 -->
          </ul>
          <!-- <div class="search-box" style="margin-left: 50px"  v-if="$route.path !== '/category'">
            <el-input
              placeholder="请输入关键字..."
              class="search-input"
              v-model="searchVal"
            >
              <i slot="suffix" class="el-icon-search" @click="handleSearch">
              </i>
            </el-input>
          </div> -->
        </div>
        <div class="nav-left">
          <!-- <i class="el-icon-search" v-show="!isShowSearchBox && $route.path !== '/home'"
            @click.stop="isShowSearchBox = true"></i>
          <div class="search-box" v-show="isShowSearchBox" @click.stop="isShowSearchBox = true">
            <el-input class="search-input" v-model="searchVal">
              <text slot="suffix" class="el-icon-search" @click="handleSearch"> 搜索</text>
            </el-input>
          </div> -->
          <MdNavClassComponent />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { getConfigType } from "@/axios/home";
import MdNavClassComponent from "../components/class/mdNav/index.vue";
export default {
  name: "MdSharedHeaderComponent",
  components: {
    MdNavClassComponent,
  },
  data() {
    return {
      logo: "",
      deptName: "",
      user: null,
      isShowSearchBox: false,
      searchVal: "",
      navList: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "课程",
          path: "/category",
        },
        {
          name: "题库",
          path: "/topics",
        },
        {
          name: "资讯",
          path: "/news",
        },
        {
          name: "名师",
          path: "/teachers",
        },
        {
          name: "直播",
          path: "/live",
        },
        {
          name: "百科",
          path: "/encyclopedia",
        },
        {
          name: "VR",
          path: "/vr",
        },
        {
          name: "下载和关注",
          path: "/download",
          child: [
            {
              name: "App",
              icon: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/logo1.png",
              img: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/download.png",
              path: "/download",
            },
            {
              name: "单词对对碰",
              icon: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/dui.png",
              img: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/wxsan.jpg",
            },
            {
              name: "微信公众号",
              icon: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/wchat.png",
              img: "https://manage.aikoolearn.com/video-api/profile/upload/common/WXAPP/img/wx-g.jpg",
            },
          ],
        },
      ],
      isLogin: false,
    };
  },
  computed: {
    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
    deptNameinfo() {
      return window.localStorage.getItem("deptName");
    },
  },
  watch: {
    "$store.state.user.dept": {
      handler(val) {
        this.deptName = val.deptName;
      },
    },
  },
  mounted() {
    this.user = this.$local.getItem("user");
    this.handleListenerScroll();
    this.getConfigType();
    this.isLogin = window.localStorage.getItem("isLogin");
    window.addEventListener("setItemEvent", (e) => {
      console.log(e.key, "key123");
      if (e.key == "isLogin") {
        this.isLogin = e.newValue;
      }
    });
  },
  beforeDestroy() {
    this.isShowSearchBox = false;
  },
  methods: {
    goPage() {
      window.open("http://www.ixuek.com");
    },
    // logo
    async getConfigType() {
      const res = await getConfigType("web:logo");
      if (res.code === 200) {
        this.logo = res.msg;
      }
    },
    goDownload() {
      this.$router.push({
        path: "download",
      });
    },
    handleSearch() {
      if (this.isLogin) {
        if (!this.searchVal) {
          this.$message.error("请输入关键字");
          return;
        }
        if (this.$route.path === "/encyclopedia") {
          window.open(
            `http://academic.eb.cnpeak.com/levels/collegiate/search/articles?query=${this.searchVal}`,
            "_blank"
          );
        } else {
          this.$router.push({
            path: "/category",
            query: {
              searchValue: this.searchVal,
            },
          });
        }
        this.searchVal = "";
      } else {
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
    handleNav(item) {
      console.log(item, "item");
      // 酷学题库、酷学资讯 新开页面
      if (["/topics"].includes(item.path)) {
        window.open(item.path);
      } else {
        this.$router.push({
          path: item.path,
        });
      }
    },
    handleGoHome() {
      window.open("/");
    },
    handleListenerScroll() {
      window.addEventListener("scroll", () => {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        this.$store.state.fixed = scrollTop > 80;
        if (["/login"].includes(this.$route.path)) {
          this.$store.state.fixed = true;
        }
      });
    },
    handleCreateActivity() {
      if (!this.user) {
        this.$router.push("/login");
      } else {
        this.$router.push("/manage/home");
      }
    },
    handleMouseover(index) {
      this.subMenusIndex = index;
    },
    handleSubMenuClick(item) {
      this.$router.push(item.url);
    },
  },
};
</script>

<style lang="less" scoped>
.md-nav-welcome {
  background-color: #f5f5f5;
  width: 100%;

  p {
    width: 1152px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    margin: 0 auto;
    color: black;
  }
}

.md-shared-header {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: @md-header-height;
  box-sizing: border-box;
  // position: absolute;
  // top: 30px;
  // left: 0px;
  z-index: 998;
  background: @md-light-primary-color;
  display: flex;
  align-items: center;

  &.md-shared-header-fixed {
    // background-color: #fff !important;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 2%) !important;
  }

  .md-nav-box {
    min-width: 1200px;
    height: @md-header-height;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 12px 0px rgba(14, 163, 143, 0.06);
    .md-nav-panel {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
        // height: @md-header-height;
        margin-right: 10px;
        width: 26px;
        height: 26px;
      }

      .class-list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        min-width: 500px;

        .class-item {
          padding: 0px 15px;
          white-space: nowrap;

          &:first-child {
            span {
              padding-left: 0;
            }
          }

          &:last-child {
            span {
              padding-right: 0;
            }
          }

          .active .item-span {
            display: block;
            font-size: 16px;
            height: 63px;
            color: black;
            line-height: 63px;
            position: relative;
          }
          span:hover {
            cursor: pointer;
            color: @md-primary-color;
          }
          .active {
            color: @md-primary-color;
            font-weight: 500;
          }
        }
      }
    }

    .down-img {
      margin-left: 16px;
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav-left {
      height: 100%;
      font-size: 16px;
      color: @md-primary-color;
      display: flex;
      align-items: center;
      // margin: 0 20px;

      i {
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: @md-primary-color;
        }
      }
    }
  }
}

.search-box {
  /deep/ .search-input {
    .el-input__inner {
      border: none;
      width: 240px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 18px;
      border: 1px solid @md-primary-color;
      height: 36px;
    }

    .el-input__suffix {
      i {
        margin-top: 10px;
        margin-right: 3px;
        font-size: 16px;
        font-weight: bold;
        color: @md-primary-color;
        cursor: pointer;

        &:hover {
          color: @md-primary-color;
        }
      }
    }
  }
}

.download {
  position: relative;
}
.download:hover {
  .child {
    display: block;
  }
}
.child-li {
  display: flex;
  align-items: center;
  padding: 8px;
  img {
    width: 20px;
    height: 20px;
  }
  span {
    line-height: 24px;
    font-size: 14px;
  }
}

.span-title {
  display: inline-block;
  font-weight: bold;
  color: @md-primary-color;
  font-size: 22px;
}
.child {
  background-color: #fff;
  padding: 4px;
  display: none;
  position: absolute;
  z-index: 20;
  border-radius: 8px;
}
.tooltip {
  ::v-deep .el-tooltip__popper.is-light {
    border: 1px solid #fff !important;
  }
}
</style>

<style lang="less">
.tooltip-header {
  border: 1px solid #e6e6e6 !important;
  margin-right: 20px !important;
}
</style>
